// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-crafting-a-design-persona-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/articles/crafting-a-design-persona.js" /* webpackChunkName: "component---src-pages-articles-crafting-a-design-persona-js" */),
  "component---src-pages-articles-prototyping-your-workflow-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/articles/prototyping-your-workflow.js" /* webpackChunkName: "component---src-pages-articles-prototyping-your-workflow-js" */),
  "component---src-pages-articles-the-language-of-modular-design-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/articles/the-language-of-modular-design.js" /* webpackChunkName: "component---src-pages-articles-the-language-of-modular-design-js" */),
  "component---src-pages-articles-ux-for-the-enterprise-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/articles/ux-for-the-enterprise.js" /* webpackChunkName: "component---src-pages-articles-ux-for-the-enterprise-js" */),
  "component---src-pages-blog-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-solutions-custom-applications-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/solutions/custom-applications.js" /* webpackChunkName: "component---src-pages-solutions-custom-applications-js" */),
  "component---src-pages-solutions-digital-design-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/solutions/digital-design.js" /* webpackChunkName: "component---src-pages-solutions-digital-design-js" */),
  "component---src-pages-solutions-ecommerce-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/solutions/ecommerce.js" /* webpackChunkName: "component---src-pages-solutions-ecommerce-js" */),
  "component---src-pages-solutions-enterprise-security-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/solutions/enterprise-security.js" /* webpackChunkName: "component---src-pages-solutions-enterprise-security-js" */),
  "component---src-pages-solutions-index-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-managed-infrastructure-js": () => import("/builds/mihaidoru/getfresh-static/src/pages/solutions/managed-infrastructure.js" /* webpackChunkName: "component---src-pages-solutions-managed-infrastructure-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/mihaidoru/getfresh-static/.cache/data.json")

